import { getCodeForMultiSelect, pasredValuesForMultiSelectDropdown } from 'utils/functions';
import store from '../config/configureStore';
import {
  createCit,
  patchCit,
  createCitEntity,
  patchCitEntity,
  removeCitEntity,
  upsertCitPerson,
  upsertCitLocation,
  upsertCitVehicle,
} from './helpers/citationHelpers';
import { uploadRMSAttachment } from './helpers/fileHelpers';
import {
  dateTimePicker,
  formatSaveDate,
  formatSaveDateFrom,
  formatSaveDateTo,
} from './TimeReducer';
import { setCircularLoading } from './ui/UiMenuReducer';
import { getService } from './service';

export const SET_ACTIVE_CITATIONS = 'SET_ACTIVE_CITATIONS';
export const SET_TOTAL_ACTIVE_CITATIONS = 'SET_TOTAL_ACTIVE_CITATIONS';
export const SET_CITATION_FROM_DATE = 'SET_CITATION_FROM_DATE';
export const SET_CITATION_TO_DATE = 'SET_CITATION_TO_DATE';
export const SET_CITATION_TIME_PERIOD_VALUE = 'SET_CITATION_TIME_PERIOD';
export const SET_CITATION_PARTY_NAME = 'SET_CITATION_PARTY_NAME';
export const SET_CITATION_PARTY_TYPE = 'SET_CITATION_PARTY_TYPE';
export const SET_CITATION_ADDRESS = 'SET_CITATION_ADDRESS';
export const SET_CITATION_QUICK_FILTER = 'SET_CITATION_QUICK_FILTER';
export const SET_CITATION_GROUP_BY = 'SET_CITATION_GROUP_BY';
export const SET_CITATION_SORT_BY = 'SET_CITATION_SORT_BY';
export const SET_CITATION_SORT_BY_DIRECTION = 'SET_CITATION_SORT_BY_DIRECTION';
export const SET_CITATION_CURRENT_VIEW = 'SET_CITATION_CURRENT_VIEW';
export const SET_CITATION_EXPAND_OR_NOT = 'SET_CITATION_EXPAND_OR_NOT';
export const SET_CITATION_FILTER_FORM_VALUES = 'SET_CITATION_FILTER_FORM_VALUES';
export const SET_SERVED_FROM_DATE = 'SET_SERVED_FROM_DATE';
export const SET_SERVED_TO_DATE = 'SET_SERVED_TO_DATE';
export const SET_SERVED_TIME_PERIOD_VALUE = 'SET_SERVED_TIME_PERIOD_VALUE';
export const CLEAR_ALL_CITATION_FILTERS = 'CLEAR_ALL_CITATION_FILTERS';

export const SET_CURRENT_CITATION = 'SET_CURRENT_CITATION';
export const SET_CITATION = 'SET_CITATION';
export const SET_CITATION_NUMBER = 'SET_CITATION_NUMBER';
export const SET_CITATION_INCIDENT_NUMBER = 'SET_CITATION_INCIDENT_NUMBER';
export const SET_CITATION_DATE = 'SET_CITATION_DATE';
export const SET_CITATION_SERVE_DATE = 'SET_CITATION_SERVE_DATE';
export const SET_CITATION_COURTS = 'SET_CITATION_COURTS';
export const SET_CITATION_COURT_NAME = 'SET_CITATION_COURT_NAME';
export const SET_CITATION_COURT_DATE = 'SET_CITATION_COURT_DATE';
export const SET_CITATION_OTHERS_INFO = 'SET_CITATION_OTHERS_INFO';
export const SET_CITATION_COURT_DAYS = 'SET_CITATION_COURT_DAYS';
export const SET_CITATION_COURT_DOCKET_NUMBER = 'SET_CITATION_COURT_DOCKET_NUMBER';
export const SET_CITATION_COURT_PAGE_NUMBER = 'SET_CITATION_COURT_PAGE_NUMBER';

export const SET_CITATION_PERSONS = 'SET_CITATION_PERSONS';
export const SET_CITATION_VEHICLES = 'SET_CITATION_VEHICLES';
export const SET_CITATION_LOCATIONS = 'SET_CITATION_LOCATIONS';
export const SET_CITATION_VIOLATIONS = 'SET_CITATION_VIOLATIONS';
export const SET_CITATION_TAGS = 'SET_CITATION_TAGS';
export const SET_CITATION_NOTES = 'SET_CITATION_NOTES';
export const SET_CITATION_ATTACHMENTS = 'SET_CITATION_ATTACHMENTS';
export const SET_CITATION_PRINTED_STATE = 'CITATION/SET_CITATION_PRINTED_STATE';

export const resetAllCitationEntitiesList = () => {
  const citation = store.store.getState().citation;

  citation.currentCitation = {
    citation: {
      ptsCitationID: 0,
      CitationID: '',
      IncidentNumber: '',
      CitationDate: new Date(),
      ServeDate: null,
      Court: '',
      CourtType: '',
      CourtDate: null,
      CourtDays: '',
      DocketNumber: '',
      PageNumber: '',
      DWI_BAC: '',
      ClockedSpeed: null,
      SpeedLimit: '',
      ReasonForStop: '',
      IsPrinted: false,
      ArrestType: null,
      SearchType: null,
      ContrabandType: null,
      WarningType: null,
      PavementCondition: null,
      HighwayType: null,
      LightingType: null,
      TrafficType: null,
      ForceUsed: '',
      IsAccident: false,
      IsArrestMade: false,
      IsContrabandFound: false,
      IsEthnicityKnownPriorToStop: false,
      IsFatal: false,
      IsJuvenile: false,
      IsPD: false,
      IsPI: false,
      IsStateCompact: false,
      IsTrafficStopSearch: false,
      IsWarning: false,
      IsWrittenOnGrantDetail: false,
      RoadType: null,
      Weather: null,
    },
    courts: [],
    persons: [],
    vehicles: [],
    locations: [],
    violations: [],
    tags: [],
    notes: [],
    attachments: [],
  };
};

function updatePersons(response) {
  const citation = store.store.getState().citation;
  const codePartyRelationship = store.store.getState().dictionary.codePartyRelationship;
  let persons = citation?.currentCitation?.persons || [];

  const { partyContactInfo, partyEmploymentInfo, ptsPerson, partyPerson } = response;

  let updatedPersons = persons.map((person) => {
    if (
      person.tempPartyId === response.tempPartyId ||
      person.ptsCitationPersonID === partyPerson.ptsCitationPersonID
    ) {
      const {
        partiesPersonForm,
        partiesContactForm,
        partiesEmploymentForm,
        partiesOthersForm,
      } = person;

      partiesEmploymentForm.ptsPlaceId = partyEmploymentInfo?.partyEmployment?.ptsPlaceID;
      partiesEmploymentForm.ptsAddressId = partyEmploymentInfo?.partyEmployment?.ptsAddressID;
      partiesEmploymentForm.ptsIncPartyEmploymentId =
        partyEmploymentInfo?.partyEmployment?.ptsCitationPersonEmploymentID;
      partiesEmploymentForm.ptsPersonId = ptsPerson.ptsPersonID;
      partiesEmploymentForm.ptsPersonID = ptsPerson.ptsPersonID;

      partiesContactForm.ptsContactId1 = partyContactInfo?.contact1?.ptsInfoID;
      partiesContactForm.ptsContactId2 = partyContactInfo?.contact2?.ptsInfoID;
      partiesContactForm.ptsContactId3 = partyContactInfo?.contact3?.ptsInfoID;
      partiesContactForm.ptsAddressId = partyContactInfo?.partyAddress?.ptsAddressID;
      partiesContactForm.ptsPersonId = ptsPerson.ptsPersonID;
      partiesContactForm.ptsPersonID = ptsPerson.ptsPersonID;
      partiesContactForm.ptsIncAddressId =
        partyContactInfo?.partyAddress?.ptsCitationPersonAddressID;

      partiesPersonForm.ptsPersonId = ptsPerson.ptsPersonID;
      partiesPersonForm.ptsPersonID = ptsPerson.ptsPersonID;
      partiesPersonForm.ptsCitationPersonID = partyPerson.ptsCitationPersonID;
      partiesPersonForm.ptsCitationPersonId = partyPerson.ptsCitationPersonID;

      const data = {
        ptsPersonID: ptsPerson.ptsPersonID,
        ptsPersonId: ptsPerson.ptsPersonID,
        ptsCitationPersonID: partyPerson.ptsCitationPersonID,
        ptsCitationPersonId: partyPerson.ptsCitationPersonID,
        tempPartyId: partiesPersonForm.tempPartyId,
        FullName: `${partiesPersonForm?.values?.lastName || ''}, ${
          partiesPersonForm?.values?.firstName || ''
        } ${partiesPersonForm?.values?.middleName || ''}`,
        Relationship:
          codePartyRelationship.find((op) => op.Code === partiesPersonForm?.values?.partyType)
            ?.Description || partiesPersonForm?.values?.partyType,
        OLNState: partiesPersonForm?.values?.state || '',
        DOB: partiesPersonForm?.values?.birthdate,
        OLNNumber: partiesPersonForm?.values?.oln || '',
        ActiveWarrant: partiesPersonForm?.values?.activeWarrant || false,
        LicensePickedUp: partiesPersonForm.values?.LicensePickedUp || false,
        IsMilitary: partiesPersonForm.values?.IsMilitary || false,
        Updated: formatSaveDate(partyPerson.Updated),
        UpdatedBy: partyPerson.UpdatedBy,
        Created: formatSaveDate(partyPerson.Created),
        CreatedBy: partyPerson.CreatedBy,
        partiesPersonForm,
        partiesContactForm,
        partiesEmploymentForm,
        partiesOthersForm,
      };

      return data;
    }
    return person;
  });

  return updatedPersons;
}

export const getCitationAttachments = (id) => {
  const client = store.store.getState().websocket.websocket;

  return async (dispatch) => {
    try {
      const service = client.service('rms-attachments');
      service.timeout = 500000;
      service
        .find({
          query: {
            id, // ptsCitationID
            type: 'Citation',
          },
        })
        .then((result) => {
          let attachmentList = [];

          if (result) {
            result.map((r) => {
              let citationAttachmentData = {
                ptsAttachmentId: r.ptsAttachmentID,
                name: r.FileName,
                type: r.FileType,
                dataURL: r.FileObject,
                description: r.Description,
                updatedBy: r.UpdatedBy,
                updated: r.Updated,
                created: r.Created,
                createdBy: r.CreatedBy,
                ParentType: r.ParentType,
                ParentID: r.ptsParentID,
                IsDeleted: r.IsDeleted,
                codeAttachmentType: r.codeAttachmentType,
              };

              attachmentList.push(citationAttachmentData);
            });
          }

          //console.log(' Attachment: ', attachmentList);

          dispatch({ type: SET_CITATION_ATTACHMENTS, payload: attachmentList });
        });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log('error att', error);
      }
    }
  };
};

export const getAllActiveCitations = (pageNo = 0, pageSize = 10) => {
  const client = store.store.getState().websocket.websocket;
  const citationFilter = store.store.getState().citation.citationFilters;
  const agencyID = store.store.getState().user.userAgency;

  return async (dispatch) => {
    try {
      const service = client.service('rms-citations');
      service.timeout = 500000;
      service
        .find({
          query: {
            pageNo,
            pageSize,
            CitationDateFrom: citationFilter?.citationFrom
              ? formatSaveDateFrom(citationFilter?.citationFrom)
              : null,
            CitationDateTo: citationFilter?.citationTo
              ? formatSaveDateTo(citationFilter?.citationTo)
              : null,
            CitationNumber: citationFilter?.dialogFilters?.citationNumber || null,
            AgencyID: agencyID,
            ServeDateFrom: citationFilter?.servedFrom
              ? formatSaveDateFrom(citationFilter?.servedFrom)
              : null,
            ServeDateTo: citationFilter?.servedTo
              ? formatSaveDateTo(citationFilter?.servedTo)
              : null,
            PersonType:
              citationFilter?.partyType?.Code || citationFilter?.dialogFilters?.partyType || null,
            ptsPersonID: citationFilter?.partyName?.ptsPersonID,
            PersonFullName:
              citationFilter?.partyName?.FullName ||
              citationFilter?.dialogFilters?.partyName?.FullName ||
              null,
            LastName: citationFilter?.dialogFilters?.lastName || null,
            FirstName: citationFilter?.dialogFilters?.firstName || null,
            MiddleName: citationFilter?.dialogFilters?.middleName || null,
            Make: citationFilter?.dialogFilters?.make || null,
            Model: citationFilter?.dialogFilters?.model || null,
            Style: citationFilter?.dialogFilters?.style || null,
            Color: citationFilter?.dialogFilters?.color || null,
            LicensePlate: citationFilter?.dialogFilters?.plate || null,
            LicenseState: citationFilter?.dialogFilters?.state || null,
            LocationFullName:
              citationFilter?.placeOrAddress?.address?.FullAddressText ||
              citationFilter?.dialogFilters?.placeOrAddress?.address?.FullAddressText ||
              null,
            ptsAddressID:
              citationFilter?.placeOrAddress?.ptsAddressID ||
              citationFilter?.dialogFilters?.placeOrAddress?.ptsAddressID ||
              null,
            LowBlock: citationFilter?.dialogFilters?.lowBlock || null,
            HighBlock: citationFilter?.dialogFilters?.highBlock || null,
            StreetName: citationFilter?.dialogFilters?.streetName || null,
            PreDirection: citationFilter?.dialogFilters?.predirectional || null,
            PostDirection: citationFilter?.dialogFilters?.postdirectional || null,
            StreetType: citationFilter?.dialogFilters?.streetType || null,
            UnitType: citationFilter?.dialogFilters?.unitType || null,
            UnitIdentifier: citationFilter?.dialogFilters?.unitNumber || null,
            ptsCityID: citationFilter?.dialogFilters?.ptsCityID || null,
            Zone: citationFilter?.dialogFilters?.zone || null,
            ReasonForStop: citationFilter?.dialogFilters?.reasonForStop || null,
            QuickFilter: citationFilter?.quickFilter || null,
          },
        })
        .then((result) => {
          // console.log('Citation Result: ', result);

          dispatch({
            type: SET_TOTAL_ACTIVE_CITATIONS,
            payload: result?.length > 0 ? result[0].Count : 0,
          });

          let activeList = [];
          if (result) {
            result.forEach((r) => {
              let locationsData = r.Locations ? JSON.parse(r.Locations) : '';
              let violationsData = r.Violations ? JSON.parse(r.Violations) : '';
              let locations = '';
              let violations = '';

              if (locationsData) {
                locationsData.forEach((l, i) => {
                  if (i === 0 && l?.Addresses) locations += l?.Addresses[0]?.FullAddressText;
                  else if (l?.Addresses) locations += `, ${l?.Addresses[0].FullAddressText}`;
                });
              }

              if (violationsData) {
                violationsData.forEach((v, i) => {
                  if (i === 0) violations += `${v.OffenseDescription} | ${v.Code} | ${v.FBICode}`;
                  else violations += `, ${v.OffenseDescription} | ${v.Code} | ${v.FBICode}`;
                });
              }

              let citationGridData = {
                CitationDate: r.CitationDate,
                AgencyID: r.AgencyID,
                CitationNumber: r.CitationNumber,
                ServeDate: r.ServeDate,
                Violator: r.Violator,
                ViolatorWarrant: r.ViolatorWarrant,
                Officer: r.Officer,
                ptsCitationID: r.ptsCitationID,
                Locations: locations,
                Violations: violations,
              };

              activeList.push(citationGridData);
            });
          }

          dispatch({ type: SET_ACTIVE_CITATIONS, payload: activeList });
        });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const getCitationCourt = () => {
  const client = store.store.getState().websocket.websocket;

  return async (dispatch) => {
    try {
      const service = client.service('citation-court');
      service.timeout = 500000;
      service
        .find({
          query: {},
        })
        .then((result) => {
          let activeList = [];

          // console.log(result);

          if (Object.keys(result).length) {
            result.data.map((r) => {
              let CourtDropDownData = {
                Code: r.Court_Name,
                Description: r.Court_Name,
              };

              activeList.push(CourtDropDownData);
            });
          }
          //Dispatch
          dispatch({ type: SET_CITATION_COURTS, payload: activeList });
        });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const getCurrentCitation = (citationID) => {
  const client = store.store.getState().websocket.websocket;

  return async (dispatch) => {
    dispatch(setCircularLoading(true));
    try {
      const service = client.service('rms-citations');
      service.timeout = 50000;
      service.get(citationID).then((result) => {
        let citationFromDB = { ...result[0] };
        let citation = {
          citation: {
            ptsCitationID: citationFromDB.ptsCitationID,
            CitationID: citationFromDB.CitationID,
            IncidentNumber: citationFromDB.IncidentNumber,
            CitationDate: dateTimePicker(citationFromDB.CitationDate),
            ServeDate: dateTimePicker(citationFromDB.ServeDate),
            Court: citationFromDB.Court,
            CourtType: citationFromDB.CourtType,
            CourtDate: dateTimePicker(citationFromDB.CourtDate),
            CourtDays: citationFromDB.CourtDays,
            DocketNumber: citationFromDB.DocketNumber,
            PageNumber: citationFromDB.PageNumber,
            DWI_BAC: citationFromDB?.DWI_BAC || '',
            ClockedSpeed: citationFromDB?.ClockedSpeed || null,
            SpeedLimit: citationFromDB?.SpeedLimit || null,
            ReasonForStop: citationFromDB?.ReasonForStop || '',
            IsSubmitted: citationFromDB.IsSubmitted || false,
            Created: formatSaveDate(citationFromDB.Created),
            CreatedBy: citationFromDB.CreatedBy,
            Updated: formatSaveDate(citationFromDB.Updated),
            UpdatedBy: citationFromDB.CreatedBy,
            IsPrinted: citationFromDB.IsPrinted || false,
            ArrestType: pasredValuesForMultiSelectDropdown(
              citationFromDB.ArrestType,
              'codeArrestType'
            ),

            SearchType: citationFromDB.SearchType || null,
            ContrabandType: pasredValuesForMultiSelectDropdown(
              citationFromDB.ContrabandType,
              'codeContrabandType'
            ),
            WarningType: citationFromDB.WarningType || null,
            PavementCondition: citationFromDB.PavementCondition || null,
            HighwayType: citationFromDB.HighwayType || null,
            LightingType: citationFromDB.LightingType || null,
            TrafficType: citationFromDB.TrafficType || null,
            ForceUsed: citationFromDB.ForceUsed || '',
            IsAccident: citationFromDB.IsAccident || false,
            IsArrestMade: citationFromDB.IsArrestMade || false,
            IsContrabandFound: citationFromDB.IsContrabandFound || false,
            IsEthnicityKnownPriorToStop: citationFromDB.IsEthnicityKnownPriorToStop || false,
            IsFatal: citationFromDB.IsFatal || false,
            IsJuvenile: citationFromDB.IsJuvenile || false,
            IsPD: citationFromDB.IsPD || false,
            IsPI: citationFromDB.IsPI || false,
            IsStateCompact: citationFromDB.IsStateCompact || false,
            IsTrafficStopSearch: citationFromDB.IsTrafficStopSearch || false,
            IsWarning: citationFromDB.IsWarning || false,
            IsWrittenOnGrantDetail: citationFromDB.IsWrittenOnGrantDetail || false,
            RoadType: citationFromDB.RoadType || null,
            Weather: citationFromDB.Weather || null,
          },
        };

        citation.locations = citationFromDB.Locations ? JSON.parse(citationFromDB.Locations) : [];
        citation.vehicles = citationFromDB.Vehicles ? JSON.parse(citationFromDB.Vehicles) : [];
        citation.violations = citationFromDB.Violations
          ? JSON.parse(citationFromDB.Violations)
          : [];
        citation.tags = citationFromDB.Tags ? JSON.parse(citationFromDB.Tags) : [];
        citation.persons = citationFromDB.Persons ? JSON.parse(citationFromDB.Persons) : [];
        citation.notes = citationFromDB.Notes ? JSON.parse(citationFromDB.Notes) : [];

        citation.persons = citation.persons.map((person) => {
          let p = {
            ptsPersonID: person.ptsPersonID,
            ptsPersonId: person.ptsPersonId,
            ptsCitationPersonID: person.ptsCitationPersonID,
            ptsCitationPersonId: person.ptsCitationPersonId,
            FullName: person.FullName,
            FirstName: person.FirstName,
            LastName: person.LastName,
            Relationship: person.partyTypeDescription || '',
            PersonOrder: person.PartyCode?.[0]?.Order,
            OLNState: person?.OLNState || '',
            DOB: person?.DOB,
            OLNNumber: person?.OLNNumber || '',
            SSN: person?.SSN || '',
            ActiveWarrant: person?.ActiveWarrant || false,
            LicensePickedUp: person.LicensePickedUp,
            IsMilitary: person.IsMilitary,
            WarrantCount: person?.WarrantCount || 0,
            Created: formatSaveDate(person?.Created) || null,
            CreatedBy: person?.CreatedBy || null,
            Updated: formatSaveDate(person?.Updated) || null,
            UpdatedBy: person?.UpdatedBy || null,
          };

          if (person.Addresses && person.Addresses.length) {
            let ContactInfo1,
              ContactInfo2,
              ContactInfo3 = null;
            let contactId1,
              contactId2,
              contactId3 = null;
            if (person.Addresses[0]?.cc[0].Contacts && person.Addresses[0]?.cc[0].Contacts.length) {
              ContactInfo1 = person.Addresses[0]?.cc[0].Contacts[0]?.Info;
              ContactInfo2 = person.Addresses[0]?.cc[0].Contacts[1]?.Info;
              ContactInfo3 = person.Addresses[0]?.cc[0].Contacts[2]?.Info;

              contactId1 = person.Addresses[0]?.cc[0].Contacts[0]?.ptsInfoID;
              contactId2 = person.Addresses[0]?.cc[0].Contacts[1]?.ptsInfoID;
              contactId3 = person.Addresses[0]?.cc[0].Contacts[2]?.ptsInfoID;
            }

            p.partiesContactForm = {
              ptsContactId1: contactId1,
              ptsContactId2: contactId2,
              ptsContactId3: contactId3,
              ptsAddressId: person?.Addresses[0]?.ptsAddressID,
              ptsIncAddressId: person?.Addresses[0]?.ptsCitationPersonAddressID,
              values: {
                cityId: person?.Addresses[0]?.ptsCityID,
                addressNumberPrefix: person?.Addresses[0]?.AddressNumberPrefix,
                addressNumber: person?.Addresses[0]?.AddressNumber,
                addressNumberSuffix: person?.Addresses[0]?.AddressNumberSuffix,
                preDirection: person?.Addresses[0]?.PreDirection,
                streetName: person?.Addresses[0]?.StreetName,
                streetType: person?.Addresses[0]?.StreetType,
                postDirection: person?.Addresses[0]?.PostDirection,
                city: person?.Addresses[0]?.cc[0]?.City,
                state: person?.Addresses[0]?.State,
                postalCode: person?.Addresses[0]?.PostalCode,
                postalCodeExtension: person?.Addresses[0]?.PostalCodeExtension,
                county: person?.Addresses[0]?.County,
                country: person?.Addresses[0]?.Country,
                fullAddressText: person?.Addresses[0]?.FullAddressText,
                textmask1: ContactInfo1,
                textmask2: ContactInfo2,
                emailmask: ContactInfo3,
                notes: person?.Addresses[0]?.Notes,
              },
            };
          }

          if (person.Employments && person.Employments.length) {
            p.partiesEmploymentForm = {
              ptsAddressId: person?.Employments[0]?.ptsAddressID,
              ptsIncAddressId: person?.Employments[0]?.ptsCitationPersonEmploymentID,
              values: {
                cityId: person?.Employments[0]?.ptsCityID,
                addressNumberPrefix: person?.Employments[0]?.AddressNumberPrefix,
                addressNumber: person?.Employments[0]?.AddressNumber,
                addressNumberSuffix: person?.Employments[0]?.AddressNumberSuffix,
                preDirection: person?.Employments[0]?.PreDirection,
                streetName: person?.Employments[0]?.StreetName,
                streetType: person?.Employments[0]?.StreetType,
                postDirection: person?.Employments[0]?.PostDirection,
                city: person?.Employments[0]?.cc[0]?.City,
                state: person?.Employments[0]?.State,
                postalCode: person?.Employments[0]?.PostalCode,
                postalCodeExtension: person?.Employments[0]?.PostalCodeExtension,
                county: person?.Employments[0]?.County,
                country: person?.Employments[0]?.Country,
                fullAddressText: person?.Employments[0]?.FullAddressText,
                textmask1: person?.Employments[0]?.ContactInfo1,
                textmask2: person?.Employments[0]?.ContactInfo2,
                emailmask: person?.Employments[0]?.ContactInfo3,
                notes: person?.Employments[0]?.Notes,
              },
            };
          }

          p.partiesOthersForm = {
            values: {
              personId: person?.ptsPersonID,
              generalAppearance: person?.GeneralAppearance,
              personNotes: person?.Notes,
            },
          };

          p = {
            ...p,
            partiesPersonForm: {
              ptsCitationPersonId: person.ptsCitationPersonID,
              ptsPersonId: person.ptsPersonID,
              PersonId: person.PersonID,
              values: {
                firstName: person.FirstName,
                lastName: person.LastName,
                middleName: person.MiddleName,
                ptsPersonId: person.ptsPersonID,
                age: person.Age,
                ageRangeIncrement: person.AgeRangeIncrement,
                ptsImageId: person.ptsImageID,
                birthdate: person.DOB,
                bloodType: person.BloodType,
                ethnicity: person.Ethnicity,
                eyeColor: person.EyeColor,
                hairColor: person.HairColor,
                hairLength: person.HairLength,
                hairStyle: person.HairStyle,
                handed: person.Handed,
                height: person.Height,
                citationId: person.ptsCitationID,
                partyType: person.Relationship,
                personId: person.PersonID,
                race: person.Race,
                sex: person.Sex,
                weight: person.Weight,
                ssn: person.SSN,
                oln: person.OLNNumber,
                state: person.OLNState,
                suffix: person.Suffix,
                isUnknownParty: person.IsUnknownParty,
                isArrestee: false,
                activeWarrant: person.ActiveWarrant,
                LicensePickedUp: person.LicensePickedUp,
                IsMilitary: person.IsMilitary,
              },
            },
          };

          return p;
        });

        if (citation.persons) citation.persons.sort((a, b) => a.PersonOrder - b.PersonOrder);

        citation.locations = citation.locations.map((location) => {
          let l = {
            ptsCitationLocationID: location.ptsCitationLocationID,
            Name: location.Name,
            Description: location.Description,
            Created: formatSaveDate(location.Created),
            CreatedBy: location.CreatedBy,
            Updated: formatSaveDate(location.Updated),
            UpdatedBy: location.UpdatedBy,
          };

          if (location.Addresses && location.Addresses.length) {
            l.addressForm = {
              ptsAddressId: location.Addresses[0].ptsAddressID,
              ptsCitationLocationID: location.Addresses[0].ptsCitationLocationID,
              ptsCitationLocationAddressID: location.Addresses[0].ptsCitationLocationAddressID,
              values: {
                ptsCitationLocationID: location.Addresses[0].ptsCitationLocationID,
                ptsCitationLocationAddressID: location.Addresses[0].ptsCitationLocationAddressID,
                fullAddressText: location.Addresses[0].FullAddressText,
                addressNumberPrefix: location.Addresses[0].AddressNumberPrefix,
                addressNumberSuffix: location.Addresses[0].AddressNumberSuffix,
                addressNumber: location.Addresses[0].AddressNumber,
                streetName: location.Addresses[0].StreetName,
                preDirection: location.Addresses[0].PreDirection,
                postDirection: location.Addresses[0].PostDirection,
                city: location.Addresses[0].cc[0].City,
                state: location.Addresses[0].State,
                county: location.Addresses[0].County,
                country: location.Addresses[0].Country,
                postalCode: location.Addresses[0].PostalCode,
                postalCodeExtension: location.Addresses[0].PostalCodeExtension,
                streetType: location.Addresses[0].StreetType,
                notes: location.Addresses[0].Notes,
                isPrimary: location.Addresses[0].IsPrimary,
                cityId: location.Addresses[0].ptsCityID,
                unitType: location.Addresses[0].UnitType,
                unitTypeIdentifier: location.Addresses[0].UnitIdentifier,
                direction: location.Addresses[0].Direction,
                location: location.Addresses[0].Location,
                intersection: location.Addresses[0].Intersection,
                secondaryLocation: location.Addresses[0].SecondaryLocation,
              },
            };

            l.addressIdForm = {
              ptsAddressId: location.Addresses[0].ptsAddressID,
              values: {
                isEventLookup: location.Addresses[0].ShowInEventLookup,
                isSexOffender: location.Addresses[0].SexOffender,
                isParolee: location.Addresses[0].Parolee,
                isProbation: location.Addresses[0].Probation,
                isNonValidated: location.Addresses[0].NonValidatedAddress,
                isCampusResidential: location.Addresses[0].IsCampusResidential,
                isCampusNonResidential: location.Addresses[0].IsCampusNonResidential,
                isNonCampus: location.Addresses[0].IsNonCampus,
                isPublic: location.Addresses[0].IsPublic,
                floorType: location.Addresses[0].FloorType,
                floorIdentifier: location.Addresses[0].FloorIdentifier,
                buildingType: location.Addresses[0].BldgType,
                buildingIdentifier: location.Addresses[0].BldgIdentifier,
              },
            };

            l.coordinatesForm = {
              ptsAddressId: location.Addresses[0].ptsAddressID,
              ptsCoordinateId: location.Addresses[0].ptsCoordinateID,
              values: {
                locationId: location.Addresses[0].ptsCitationLocationID,
                addressId: location.Addresses[0].ptsAddressID,
                coordinateId: location.Addresses[0].ptsCoordinateID,
                latitudeSign: location.Addresses[0].LatitudeSign,
                latitudeDegree: location.Addresses[0].LatitudeDegree,
                longitudeSign: location.Addresses[0].LongitudeSign,
                longitudeDegree: location.Addresses[0].LongitudeDegree,
                uncertaintyDistance: location.Addresses[0].UnCertaintyDistance,
                datum: location.Addresses[0].Datum,
                coordinateDatetime: location.Addresses[0].CoordinateDT,
                altitude: location.Addresses[0].Altitude,
                altitudePor: location.Addresses[0].AltitudeRefPoint,
                altitudeUom: location.Addresses[0].AltitudeUnitMeasure,
                cellTower: location.Addresses[0].CellIDText,
                sectorId: location.Addresses[0].CellSectorIDText,
                speed: location.Addresses[0].Speed,
                speedUom: location.Addresses[0].SpeedUnitMeasure,
              },
            };
          }

          return l;
        });

        citation.violations = citation.violations.map((violation) => {
          let v = {
            ptsCitationViolationID: violation.ptsCitationViolationID,
            ptsCitationID: violation.ptsCitationID,
            ptsStatuteID: violation.ptsStatuteID,
            ptsFBICodeID: violation.ptsFBICodeID,
            Description: violation.Description,
            StatuteID: violation.StatuteID,
            ReasonForStop: violation.ReasonForStop,
            SpeedLimit: violation.SpeedLimit,
            ClockedSpeed: violation.ClockedSpeed,
            DWI_BAC: violation.DWI_BAC,
            RadarSerialNumber: violation.RadarSerialNumber,
            CalibrationChecked: violation.CalibrationChecked,
            ConvictionStatus: violation.ConvictionStatus,
            ConvictionDate: formatSaveDate(violation.ConvictionDate),
            PointsBeginDate: formatSaveDate(violation.PointsBeginDate),
            PointsExpireDate: formatSaveDate(violation.PointsExpireDate),

            Created: formatSaveDate(violation?.Created),
            CreatedBy: violation?.CreatedBy,
            Updated: formatSaveDate(violation?.Updated),
            UpdatedBy: violation?.UpdatedBy,
          };

          v.statuteDetails = {
            ptsStatuteID: violation.ptsStatuteID,
            ptsFBICodeID: violation.ptsFBICodeID,
            RSA: violation.RSA,
            Description: violation.OffenseDescription,
            Code: violation.Code,
            StatuteID: violation.StatuteID,
            FBICode: violation.FBICode,
            IsActive: violation.IsActive,
            IsDeleted: violation.IsDeleted,
          };

          return v;
        });

        citation.vehicles = citation.vehicles.map((vehicle) => {
          let v = {
            ptsCitationVehicleID: vehicle.ptsCitationVehicleID,
            ptsCitationID: vehicle.ptsCitationID,
            ptsVehicleID: vehicle.ptsVehicleID,
            VIN: vehicle.VIN,
            LicensePlate: vehicle.LicensePlate,
            LicensePlateExpiration: vehicle.LicensePlateExpiration,
            LicenseState: vehicle.LicenseState,
            Make: vehicle.Make,
            Model: vehicle.Model,
            Style: vehicle.Style,
            Year: vehicle.Year,
            Color: vehicle.Color,
            Created: formatSaveDate(vehicle?.Created) || null,
            CreatedBy: vehicle?.CreatedBy || null,
            Updated: formatSaveDate(vehicle?.Updated) || null,
            UpdatedBy: vehicle?.UpdatedBy || null,
          };

          v.propertyVehicleDetailsForm = {
            ptsCitationVehicleID: vehicle.ptsCitationVehicleID,
            ptsCitationID: vehicle.ptsCitationID,
            ptsVehicleID: vehicle.ptsVehicleID,
            values: {
              isTowed: vehicle.IsTowed,
              towedBy: vehicle.TowedBy,
              isSeatBeltInUse: vehicle.IsSeatBeltInUse,
              isHazard: vehicle.IsHazard,
              isCommercial: vehicle.IsCommercial,
              isAccident: vehicle.IsAccident,
              year: vehicle.Year,
              make: vehicle.Make,
              model: vehicle.Model,
              style: vehicle.Style,
              passengerQuantity: vehicle.PassengerSafeQty,
              seatQuantity: vehicle.SeatQty,
              primaryColor: vehicle.Color,
              secondaryColor: vehicle.SecondaryColor,
              interior: vehicle.InteriorColor,
              classification: vehicle.Classification,
              brand: vehicle.Brand,
              brander: vehicle.Brander,
              description: vehicle.Description,
            },
          };

          v.propertyCredentialsAnalysisForm = {
            ptsCitationVehicleID: vehicle.ptsCitationVehicleID,
            ptsCitationID: vehicle.ptsCitationID,
            ptsVehicleID: vehicle.ptsVehicleID,
            values: {
              vin: vehicle.VIN,
              vinAnalysisTest: vehicle.VINAnalysisText,
              make: vehicle.Make,
              model: vehicle.Model,
              description: vehicle.Description,
              year: vehicle.Year,
              brander: vehicle.Brander,
              rented: vehicle.IsRented,
              wanted: vehicle.IsWanted,
              seatQuantity: vehicle.SeatQty,
              passengerQuantity: vehicle.PassengerSafeQty,
              transmissionCode: vehicle.Transmission,
              emissionInspection: vehicle.EmissionInspectionQty,
              commercialId: vehicle.CommercialID,
              commercialCode: vehicle.Commercial,
              cmv: vehicle.IsCMVIndicator,
              garageFacility: vehicle.GarageFacility,
              garageIndicator: vehicle.GarageIndicator,
              classification: vehicle.Classification,
              primaryColor: vehicle.Color,
              secondaryColor: vehicle.SecondaryColor,
              interior: vehicle.InteriorColor,
              style: vehicle.Style,
              plateNumber: vehicle.LicensePlate,
              plateState: vehicle.LicenseState,
              propertyId: vehicle.PropertyID,
              propertyCode: vehicle.Property,
              plateStatus: vehicle.PlateStatus,
              plateColor: vehicle.PlateColor,
              plateType: vehicle.PlateType,
              plateExpiration: vehicle.LicensePlateExpiration,
              plateDetail: vehicle.PlateDetail,
              plateBackgroundText: vehicle.PlateBackgroundText,
            },
          };

          return v;
        });

        citation.tags = citation.tags.map((tag) => {
          return {
            ...tag,
            Created: formatSaveDate(tag.Created),
            Updated: formatSaveDate(tag.Updated),
          };
        });

        citation.notes = citation.notes.map((note) => {
          return {
            ...note,
            Created: formatSaveDate(note.CreatedDate),
            Updated: formatSaveDate(note.UpdatedDate),
          };
        });

        // console.log('current citation to save to redux', citation);

        dispatch(setCircularLoading(false));
        dispatch({ type: SET_CURRENT_CITATION, payload: citation });
      });
    } catch (error) {
      console.log(error);
    }
  };
};
const porcessCitationDataForSave = (citationValues = {}) => {
  const agencyID = store.store.getState().user.userAgency;

  return {
    ptsCitationID: citationValues.ptsCitationID,
    CitationID: citationValues.CitationID,
    IncidentNumber: citationValues.IncidentNumber,
    Court: citationValues.Court,
    CourtType: citationValues.CourtType,
    CourtDate: citationValues.CourtDate,
    CourtDays: citationValues.CourtDays,
    DocketNumber: citationValues.DocketNumber,
    PageNumber: citationValues.PageNumber,
    AgencyID: agencyID,
    CitationDate: citationValues.CitationDate,
    ServeDate: citationValues.ServeDate,
    DWI_BAC: citationValues.DWI_BAC || '',
    ClockedSpeed: citationValues.ClockedSpeed || null,
    SpeedLimit: citationValues.SpeedLimit || null,
    ReasonForStop: citationValues.ReasonForStop || '',

    ArrestType: getCodeForMultiSelect(citationValues.ArrestType),
    SearchType: citationValues.SearchType?.Code || null,
    PavementCondition: citationValues.PavementCondition?.Code || null,
    HighwayType: citationValues.HighwayType?.Code || null,
    LightingType: citationValues.LightingType?.Code || null,
    TrafficType: citationValues.TrafficType?.Code || null,
    ContrabandType: getCodeForMultiSelect(citationValues.ContrabandType),
    WarningType: citationValues.WarningType?.Code || null,

    ForceUsed: citationValues.ForceUsed?.Code || '',
    IsAccident: citationValues.IsAccident || false,
    IsArrestMade: citationValues.IsArrestMade || false,
    IsContrabandFound: citationValues.IsContrabandFound || false,
    IsEthnicityKnownPriorToStop: citationValues.IsEthnicityKnownPriorToStop || false,
    IsFatal: citationValues.IsFatal || false,
    IsJuvenile: citationValues.IsJuvenile || false,
    IsPD: citationValues.IsPD || false,
    IsPI: citationValues.IsPI || false,
    IsStateCompact: citationValues.IsStateCompact || false,
    IsTrafficStopSearch: citationValues.IsTrafficStopSearch || false,
    IsWarning: citationValues.IsWarning || false,
    IsWrittenOnGrantDetail: citationValues.IsWrittenOnGrantDetail || false,
    RoadType: citationValues.RoadType?.Code || null,
    Weather: citationValues.Weather?.Code || null,
  };
};
export const setCitation = (forPrinting) => {
  const citation = store.store.getState().citation;
  const agencyID = store.store.getState().user.userAgency;
  const currentDate = new Date();

  return async (dispatch) => {
    let citationId = citation?.currentCitation?.citation?.CitationID;

    if (!citationId) citationId = await createCitationNumber();
    const citationValues = citation?.currentCitation?.citation || {};
    const contrabandType = citationValues.ContrabandType;
    const arrestType = citationValues.ArrestType;
    citationValues['CitationID'] = citationId;
    let cit = porcessCitationDataForSave(citationValues);

    if (forPrinting) {
      cit['IsPrinted'] = true;
    }

    if (cit.ptsCitationID === 0) {
      delete cit.ptsCitationID;
      cit = await createCit(cit);

      cit = {
        ...cit,
        Created: formatSaveDate(currentDate),
        Updated: formatSaveDate(currentDate),
      };
    } else {
      cit = await patchCit(cit.ptsCitationID, cit);

      cit = {
        ...cit,
        Created: formatSaveDate(cit?.Created),
        Updated: formatSaveDate(cit?.Updated),
      };
    }
    cit = {
      ...cit,
      ArrestType: arrestType,
      ContrabandType: contrabandType,
    };
    dispatch({ type: SET_CITATION, payload: cit || {} });
  };
};

export const createCitationNumber = async () => {
  const client = store.store.getState().websocket.websocket;
  const agencyID = store.store.getState().user.userAgency;

  const data = { EntityName: 'Citation', AgencyID: agencyID };

  const customIdService = client.service('custom-ids');
  customIdService.timeout = 20000;

  let checkedCitationId;
  let citationId;

  while (checkedCitationId !== 'ID Not Found') {
    try {
      citationId = await customIdService.create(data);

      checkedCitationId = await customIdService.get({
        entity: 'Citation',
        id: citationId,
      });
    } catch (err) {
      console.log(err);
      break;
    }
  }

  return citationId;
};

//TODO: Will Refactor
export const createCitationEntity = (entity, data) => {
  const client = store.store.getState().websocket.websocket;
  const citation = store.store.getState().citation;
  const user = store.store.getState().user.userData?.user.Username;
  const agencyID = store.store.getState().user.userAgency;
  const currentDate = new Date();

  return async (dispatch) => {
    dispatch(setCircularLoading(true));
    const citationValues = citation?.currentCitation?.citation || {};
    const contrabandType = citationValues.ContrabandType;
    const arrestType = citationValues.ArrestType;
    let cit = porcessCitationDataForSave(citationValues);

    // console.log('createCitationEntity, cit=', cit);

    if (!cit.ptsCitationID) {
      delete cit.ptsCitationID;

      if (!cit.CitationID) cit.CitationID = await createCitationNumber();

      cit = await createCit(cit);
    } else {
      // console.log('else');
      cit = await patchCit(cit.ptsCitationID, cit);
    }
    // console.log('cit', cit);
    // console.log('Patched cit =', cit, 'entity =', entity);
    cit = {
      ...cit,
      ArrestType: arrestType,
      ContrabandType: contrabandType,
    };

    // Notes
    if (entity === 'ptscomments') {
      let notes = citation?.currentCitation?.notes || [];

      data = {
        ...data,
        ptsParentID: cit.ptsCitationID,
        CreatedBy: user,
        ParentType: 'Citation',
      };
      let newEntityitem;

      if (data.ptsCommentID) {
        newEntityitem = await patchCitEntity(data.ptsCommentID, data, entity);

        notes = notes.map((note) => {
          if (note.ptsCommentID === data.ptsCommentID) {
            note = {
              ...data,
              Updated: formatSaveDate(newEntityitem.Updated),
              UpdatedBy: newEntityitem.UpdatedBy,
            };
          }
          return note;
        });
      } else {
        newEntityitem = await createCitEntity(data, entity);
        data = {
          ...data,
          ptsCommentID: newEntityitem.ptsCommentID,
          Created: formatSaveDate(currentDate),
          CreatedBy: newEntityitem.CreatedBy,
          Updated: formatSaveDate(currentDate),
          UpdatedBy: newEntityitem.UpdatedBy,
        };

        notes.push(data);
      }

      dispatch({ type: SET_CITATION_NOTES, payload: notes });
    } else if (entity === 'ptstags') {
      let tags = citation?.currentCitation?.tags || [];

      data = {
        ...data,
        ptsParentID: cit.ptsCitationID,
        CreatedBy: user,
        ParentType: 'Citation',
      };
      let newEntityitem;
      if (data.ptsTagID) {
        newEntityitem = await patchCitEntity(data.ptsTagID, data, entity);
        tags = tags.map((tag) => {
          if (tag.ptsTagID === data.ptsTagID) {
            tag = {
              ...data,
              Updated: formatSaveDate(newEntityitem.Updated),
              UpdatedBy: newEntityitem.UpdatedBy,
            };
          }
          return tag;
        });
      } else {
        newEntityitem = await createCitEntity(data, entity);

        data = {
          ...data,
          ptsTagID: newEntityitem.ptsTagID,
          Created: formatSaveDate(currentDate),
          CreatedBy: newEntityitem.CreatedBy,
          Updated: formatSaveDate(currentDate),
          UpdatedBy: newEntityitem.UpdatedBy,
        };

        tags.push(data);
      }

      dispatch({ type: SET_CITATION_TAGS, payload: tags });
    } else if (entity === 'citcitationviolations') {
      let violations = citation?.currentCitation?.violations || [];

      data = {
        ...data,
        ptsStatuteID: data?.statuteDetails?.ptsStatuteID,
        ptsCitationID: cit?.ptsCitationID,
        ConvictionStatus: data.ConvictionStatus?.Code || null,
      };

      let newEntityitem;
      if (data?.ptsCitationViolationID) {
        newEntityitem = await patchCitEntity(data.ptsCitationViolationID, data, entity);
        violations = violations.map((violation) => {
          if (violation.ptsCitationViolationID === data.ptsCitationViolationID) {
            violation = {
              ...data,
              Updated: formatSaveDate(newEntityitem?.Updated),
              UpdatedBy: user,
            };
          }
          return violation;
        });
      } else {
        newEntityitem = await createCitEntity(data, entity);

        data = {
          ...data,
          ptsCitationViolationID: newEntityitem?.ptsCitationViolationID,
          Updated: formatSaveDate(currentDate),
          UpdatedBy: newEntityitem?.UpdatedBy,
          Created: formatSaveDate(currentDate),
          CreatedBy: newEntityitem?.CreatedBy,
        };
        violations.push(data);
      }

      dispatch({ type: SET_CITATION_VIOLATIONS, payload: violations });
    } else if (entity === 'rms-attachments') {
      const { file, type, description, codeAttachmentType } = data;
      const newEntityitem = await uploadRMSAttachment(
        cit.ptsCitationID,
        file,
        'Citation',
        description,
        user,
        codeAttachmentType
      );
      if (Object.keys(newEntityitem).length) {
        dispatch(getCitationAttachments(cit.ptsCitationID));
      }
    } else {
      if (entity === 'citcitationpersons') {
        let persons = citation?.currentCitation?.persons || [];
        const incidentState = store.store.getState().incident;
        const {
          partiesPersonForm,
          partiesContactForm,
          partiesEmploymentForm,
          partiesOthersForm,
        } = incidentState;

        const data = {
          tempPartyId: partiesPersonForm.tempPartyId,
          ptsPersonID: partiesPersonForm.ptsPersonID,
          ptsPersonId: partiesPersonForm.ptsPersonId,
          ptsCitationPersonID: partiesPersonForm.ptsCitationPersonID,
          ptsCitationPersonId: partiesPersonForm.ptsCitationPersonId,
          FullName: `${partiesPersonForm?.values?.lastName || ''}, ${
            partiesPersonForm?.values?.firstName || ''
          } ${partiesPersonForm?.values?.middleName || ''}`,
          Relationship: partiesPersonForm?.values?.partyType || '',
          OLNState: partiesPersonForm?.values?.state || '',
          DOB: partiesPersonForm?.values?.birthdate,
          OLNNumber: partiesPersonForm?.values?.oln || '',
          SSN: partiesPersonForm?.values?.ssn || '',
          LicensePickedUp: partiesPersonForm?.values?.LicensePickedUp || false,
          IsMilitary: partiesPersonForm?.values?.IsMilitary || false,
          ActiveWarrant: partiesPersonForm?.values?.activeWarrant || false,
          partiesPersonForm,
          partiesContactForm,
          partiesEmploymentForm,
          partiesOthersForm,
        };

        if (partiesPersonForm.ptsCitationPersonId) {
          persons = persons.map((person) => {
            if (person.ptsCitationPersonID === data.ptsCitationPersonId) {
              person = {
                ...data,
                Created: person.Created,
                CreatedBy: person.CreatedBy,
                Updated: formatSaveDate(currentDate),
                UpdatedBy: user,
              };
            }
            return person;
          });
        } else {
          persons.push({
            ...data,
            Created: formatSaveDate(currentDate),
            CreatedBy: user,
            Updated: formatSaveDate(currentDate),
            UpdatedBy: user,
          });
        }
        dispatch({ type: SET_CITATION_PERSONS, payload: persons });

        await upsertCitPerson(cit?.ptsCitationID).then((response) => {
          let updatedPersons = updatePersons(response);
          dispatch({ type: SET_CITATION_PERSONS, payload: updatedPersons });
        });
      } else if (entity === 'citcitationvehicles') {
        let vehicles = citation?.currentCitation?.vehicles || [];
        const incidentState = store.store.getState().incident;
        const { propertyVehicleDetailsForm, propertyCredentialsAnalysisForm } = incidentState;

        const data = {
          tempPropId: propertyVehicleDetailsForm.tempPropId,
          ptsCitationVehicleID: propertyVehicleDetailsForm.ptsCitationVehicleID,
          ptsCitationID: propertyVehicleDetailsForm.ptsCitationID,
          ptsVehicleID: propertyVehicleDetailsForm.ptsVehicleID,
          VIN: propertyCredentialsAnalysisForm?.values?.vin,
          LicensePlate: propertyCredentialsAnalysisForm?.values?.plateNumber,
          LicensePlateExpiration: propertyCredentialsAnalysisForm?.values?.plateExpiration,
          LicenseState: propertyCredentialsAnalysisForm?.values?.plateState,
          Make: propertyVehicleDetailsForm?.values?.make,
          Model: propertyVehicleDetailsForm?.values?.model,
          Style: propertyVehicleDetailsForm?.values?.style,
          Year: propertyVehicleDetailsForm?.values?.year,
          Color: propertyVehicleDetailsForm?.values?.primaryColor,
          propertyVehicleDetailsForm,
          propertyCredentialsAnalysisForm,
        };

        if (propertyVehicleDetailsForm.ptsCitationVehicleID) {
          vehicles = vehicles.map((vehicle) =>
            vehicle.ptsCitationVehicleID === data.ptsCitationVehicleID
              ? {
                  ...data,
                  Created: propertyVehicleDetailsForm?.values?.created,
                  CreatedBy: propertyVehicleDetailsForm?.values?.createdBy,
                  Updated: formatSaveDate(currentDate),
                  UpdatedBy: user,
                }
              : vehicle
          );
          dispatch({ type: SET_CITATION_VEHICLES, payload: vehicles });
          const response = await upsertCitVehicle(cit.ptsCitationID);
          // console.log('Update existing vehicle response: ', response);
        } else {
          const response = await upsertCitVehicle(cit.ptsCitationID);
          // console.log('Create new Vehicle Response:', response);
          data.ptsCitationVehicleID = response?.ptsCitationVehicleID;
          data.ptsCitationID = response?.ptsCitationID;
          data.ptsVehicleID = response?.ptsVehicleID;
          data.VIN = response?.VIN;
          data.LicensePlate = response?.LicensePlate;
          data.LicensePlateExpiration = response?.LicensePlateExpiration;
          data.LicenseState = response?.LicenseState;
          data.Make = response?.Make;
          data.Model = response?.Model;
          data.Style = response?.Style;
          data.Year = response?.Year;
          data.Color = response?.Color;
          data.Created = formatSaveDate(currentDate);
          data.CreatedBy = user;
          data.Updated = formatSaveDate(currentDate);
          data.UpdatedBy = user;
          data.propertyVehicleDetailsForm.ptsCitationVehicleID = response?.ptsCitationVehicleID;
          data.propertyVehicleDetailsForm.ptsCitationID = response?.ptsCitationID;
          data.propertyVehicleDetailsForm.ptsVehicleID = response?.ptsVehicleID;
          vehicles.push(data);
          dispatch({ type: SET_CITATION_VEHICLES, payload: vehicles });
        }
      } else if (entity === 'citcitationlocations') {
        let locations = citation?.currentCitation?.locations || [];
        const incidentState = store.store.getState().incident;
        const { addressForm, addressIdForm, coordinatesForm } = incidentState;

        const data = { addressForm, addressIdForm, coordinatesForm };
        if (!addressForm.values.ptsCitationLocationID) {
          const response = await upsertCitLocation(cit.ptsCitationID); // create new instance in db
          const result = response?.locationAddress;
          data.ptsCitationLocationID = result.ptsCitationLocationID;
          data.Name = result.FullAddressText;
          data.Description = result.Notes;
          data.Created = formatSaveDate(currentDate);
          data.CreatedBy = result?.CreatedBy;
          data.Updated = formatSaveDate(currentDate);
          data.UpdatedBy = result?.UpdatedBy;
          addressForm.ptsAddressId = result.ptsAddressID;
          addressForm.ptsCitationLocationID = result.ptsCitationLocationID;
          addressForm.ptsCitationLocationAddressID = result.ptsCitationLocationAddressID;
          addressForm.values.ptsCitationLocationID = result.ptsCitationLocationID;
          addressForm.values.ptsCitationLocationAddressID = result.ptsCitationLocationAddressID;
          addressIdForm.ptsAddressId = result.ptsAddressID;
          coordinatesForm.ptsAddressId = result.ptsAddressID;
          coordinatesForm.ptsCoordinateId = result.ptsCoordinateId;
          locations.push(data);
          // console.log('Locations', locations);
          dispatch({ type: SET_CITATION_LOCATIONS, payload: locations }); // update store
        } else {
          locations = locations.map((location) => {
            if (location.ptsCitationLocationID === addressForm.values.ptsCitationLocationID) {
              return {
                ...location,
                ...data,
                Updated: formatSaveDate(currentDate),
                UpdatedBy: user,
                ptsCitationLocationID: addressForm.values.ptsCitationLocationID,
                Name: addressForm.values.fullAddressText,
                Description: addressForm.values.notes,
              };
            }
            return location;
          });

          dispatch({ type: SET_CITATION_LOCATIONS, payload: locations });
          await upsertCitLocation(cit.ptsCitationID); // update database
        }
      }
    }
    dispatch({ type: SET_CITATION, payload: cit });

    dispatch(setCircularLoading(false));
  };
};

export const createDuplicateCitation = async (citationId) => {
  const citation = store.store.getState().citation;
  const agencyID = store.store.getState().user.userAgency;

  const citationValues = citation?.currentCitation?.citation || [];
  const service = getService('rms-citations');
  const ptsCitationID = citationValues.ptsCitationID;
  const data = {
    type: 'duplicate-citation',
    data: { ptsCitationID, AgencyID: agencyID, citationId },
  };
  const res = await service.create(data);
  return res?.ptsCitationID;
};

export const removeCitationEntity = (entity, id) => {
  const citation = store.store.getState().citation;
  return (dispatch) => {
    if (entity === 'ptscomments') {
      let notes = citation?.currentCitation?.notes || [];

      notes = notes.filter((note) => note.ptsCommentID !== id);

      removeCitEntity(entity, id);

      dispatch({ type: SET_CITATION_NOTES, payload: notes });
    } else if (entity === 'ptstags') {
      let tags = citation?.currentCitation?.tags || [];

      tags = tags.filter((tag) => tag.ptsTagID !== id);

      removeCitEntity(entity, id);

      dispatch({ type: SET_CITATION_TAGS, payload: tags });
    } else if (entity === 'citcitationviolations') {
      let violations = citation?.currentCitation?.violations || [];

      violations = violations.filter((violation) => violation.ptsCitationViolationID !== id);

      removeCitEntity(entity, id);

      dispatch({ type: SET_CITATION_VIOLATIONS, payload: violations });
    } else if (entity === 'citcitationvehicles') {
      let vehicles = citation?.currentCitation?.vehicles || [];

      vehicles = vehicles.filter((vehicle) => vehicle.ptsCitationVehicleID !== id);

      removeCitEntity(entity, id);

      dispatch({ type: SET_CITATION_VEHICLES, payload: vehicles });
    } else if (entity === 'citcitationpersons') {
      let persons = citation?.currentCitation?.persons || [];

      persons = persons.filter((person) => person.ptsCitationPersonID !== id);

      removeCitEntity(entity, id);

      dispatch({ type: SET_CITATION_PERSONS, payload: persons });
    } else if (entity === 'citcitationlocations') {
      let locations = citation?.currentCitation?.locations || [];

      locations = locations.filter((location) => location.ptsCitationLocationID !== id);

      removeCitEntity(entity, id);

      dispatch({ type: SET_CITATION_LOCATIONS, payload: locations });
    }
  };
};

export const isCitationNumberDuplicate = async (number, currentCitationId) => {
  const client = store.store.getState().websocket.websocket;
  const agencyID = store.store.getState().user.userAgency;

  const service = client.service('citcitations');
  service.timeout = 20000;

  const citationDetails = await service.find({
    query: {
      CitationID: number,
      ptsCitationID: {
        $ne: currentCitationId,
      },
    },
  });

  return citationDetails;
};

/**
 * Citation Form Action
 */
export const setCitationNumber = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_NUMBER, payload: data });
  };
};

export const setCitationIncidentNumber = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_INCIDENT_NUMBER, payload: data });
  };
};

export const setCitationDate = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_DATE, payload: data });
  };
};

export const setCitationServeDate = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_SERVE_DATE, payload: data });
  };
};

export const setCitationCourt = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_COURT_NAME, payload: data });
  };
};

export const setCitationCourtDate = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_COURT_DATE, payload: data });
  };
};
export const setCitaionOthersInfo = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_OTHERS_INFO, payload: data });
  };
};
export const setCitationCourtDays = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_COURT_DAYS, payload: data });
  };
};

export const setCitationCourtDocketNumber = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_COURT_DOCKET_NUMBER, payload: data });
  };
};

export const setCitationCourtPageNumber = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_COURT_PAGE_NUMBER, payload: data });
  };
};

/**
 * Filter Form Actions
 */
export const setCitationFromDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_FROM_DATE, payload: date });
  };
};

export const setCitationToDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_TO_DATE, payload: date });
  };
};

export const setServedFromDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_SERVED_FROM_DATE, payload: date });
  };
};

export const setServedToDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_SERVED_TO_DATE, payload: date });
  };
};

export const setCitationTimePeriodValue = (timePeriod) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_TIME_PERIOD_VALUE, payload: timePeriod });
  };
};

export const setServedTimePeriodValue = (timePeriod) => {
  return (dispatch) => {
    dispatch({ type: SET_SERVED_TIME_PERIOD_VALUE, payload: timePeriod });
  };
};

export const setPartyName = (partyName) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_PARTY_NAME, payload: partyName });
  };
};

export const setPartyType = (partyType) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_PARTY_TYPE, payload: partyType });
  };
};

export const setAddress = (address) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_ADDRESS, payload: address });
  };
};

export const setQuickFilter = (quickFilter) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_QUICK_FILTER, payload: quickFilter });
  };
};

export const setGroupBy = (groupBy) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_GROUP_BY, payload: groupBy });
  };
};

export const setSortBy = (sortBy) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_SORT_BY, payload: sortBy });
  };
};

export const setSortByDirection = (direction) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_SORT_BY_DIRECTION, payload: direction });
  };
};

export const setCurrentView = (currentView) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_CURRENT_VIEW, payload: currentView });
  };
};

export const setExpandOrNot = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_EXPAND_OR_NOT, payload: value });
  };
};

export const setCitationFilterFormValues = (formValues) => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_FILTER_FORM_VALUES, payload: formValues });
  };
};
export const setCitationPrintedState = () => {
  return (dispatch) => {
    dispatch({ type: SET_CITATION_PRINTED_STATE });
  };
};
export const clearAllFilters = () => {
  const filterValue = {
    citationFrom: null,
    citationTo: null,
    servedFrom: null,
    servedTo: null,
    citationTimePeriod: 'Custom',
    servedTimePeriod: 'Custom',
    partyName: null,
    partyType: '',
    placeOrAddress: '',
    quickFilter: '',
    dialogFilters: {
      partyType: '',
      partyName: null,
      lastName: '',
      firstName: '',
      middleName: '',
      make: '',
      model: '',
      plate: '',
      state: '',
      style: '',
      color: '',
      placeOrAddress: '',
      lowBlock: '',
      highBlock: '',
      predirectional: '',
      streetName: '',
      postdirectional: '',
      streetType: '',
      unitType: '',
      unitNumber: '',
      city: '',
      zone: '',
      reasonForStop: '',
      citationNumber: '',
    },
  };

  return (dispatch) => {
    dispatch({ type: CLEAR_ALL_CITATION_FILTERS, payload: filterValue });
  };
};

export default function reducer(
  state = {
    activeCitations: [],
    totalActiveCitations: 0,
    currentCitation: {
      citation: {
        ptsCitationID: 0,
        CitationID: '',
        IncidentNumber: '',
        CitationDate: null,
        ServeDate: null,
        Court: '',
        CourtType: '',
        CourtDate: null,
        CourtDays: '',
        DocketNumber: '',
        PageNumber: '',
        DWI_BAC: '',
        ClockedSpeed: null,
        SpeedLimit: null,
        ReasonForStop: '',
        IsPrinted: false,
      },
      courts: [],
      persons: [],
      vehicles: [],
      locations: [],
      violations: [],
      tags: [],
      notes: [],
      attachments: [],
    },
    citationFilters: {
      citationFrom: null,
      citationTo: null,
      servedFrom: null,
      servedTo: null,
      citationTimePeriod: 'Custom',
      servedTimePeriod: 'Custom',
      partyName: null,
      partyType: '',
      placeOrAddress: '',
      quickFilter: '',
      groupBy: '',
      sortBy: 'Incident Number',
      sortByDirection: 'DESC',
      currentView: 'citationGridView',
      expandOrNot: false,
      dialogFilters: {
        partyType: '',
        partyName: null,
        lastName: '',
        firstName: '',
        middleName: '',
        make: '',
        model: '',
        plate: '',
        state: '',
        style: '',
        color: '',
        placeOrAddress: '',
        lowBlock: '',
        highBlock: '',
        predirectional: '',
        streetName: '',
        postdirectional: '',
        streetType: '',
        unitType: '',
        unitNumber: '',
        city: '',
        zone: '',
        reasonForStop: '',
        citationNumber: '',
      },
    },
  },
  action
) {
  switch (action.type) {
    case SET_CURRENT_CITATION:
      return {
        ...state,
        currentCitation: { ...state.currentCitation, ...action.payload },
      };
    case SET_CITATION:
      return {
        ...state,
        currentCitation: { ...state.currentCitation, citation: { ...action.payload } },
      };
    case SET_CITATION_COURT_NAME:
      return {
        ...state,
        currentCitation: {
          ...state.currentCitation,
          citation: {
            ...state.currentCitation.citation,
            ...action.payload,
          },
        },
      };
    case SET_CITATION_COURTS:
      return {
        ...state,
        currentCitation: { ...state.currentCitation, courts: [...action.payload] },
      };
    case SET_CITATION_COURT_DATE:
      return {
        ...state,
        currentCitation: {
          ...state.currentCitation,
          citation: { ...state.currentCitation.citation, CourtDate: action.payload },
        },
      };
    case SET_CITATION_OTHERS_INFO:
      return {
        ...state,
        currentCitation: {
          ...state.currentCitation,
          citation: { ...state.currentCitation.citation, ...action.payload },
        },
      };
    case SET_CITATION_COURT_DAYS:
      return {
        ...state,
        currentCitation: {
          ...state.currentCitation,
          citation: { ...state.currentCitation.citation, CourtDays: action.payload },
        },
      };
    case SET_CITATION_COURT_DOCKET_NUMBER:
      return {
        ...state,
        currentCitation: {
          ...state.currentCitation,
          citation: { ...state.currentCitation.citation, DocketNumber: action.payload },
        },
      };
    case SET_CITATION_COURT_PAGE_NUMBER:
      return {
        ...state,
        currentCitation: {
          ...state.currentCitation,
          citation: { ...state.currentCitation.citation, PageNumber: action.payload },
        },
      };
    case SET_CITATION_NUMBER:
      return {
        ...state,
        currentCitation: {
          ...state.currentCitation,
          citation: { ...state?.currentCitation?.citation, CitationID: action.payload },
        },
      };
    case SET_CITATION_INCIDENT_NUMBER:
      return {
        ...state,
        currentCitation: {
          ...state.currentCitation,
          citation: { ...state?.currentCitation?.citation, IncidentNumber: action.payload },
        },
      };
    case SET_CITATION_DATE:
      return {
        ...state,
        currentCitation: {
          ...state.currentCitation,
          citation: { ...state?.currentCitation?.citation, CitationDate: action.payload },
        },
      };
    case SET_CITATION_SERVE_DATE:
      return {
        ...state,
        currentCitation: {
          ...state.currentCitation,
          citation: { ...state?.currentCitation?.citation, ServeDate: action.payload },
        },
      };
    case SET_CITATION_PERSONS:
      return {
        ...state,
        currentCitation: { ...state.currentCitation, persons: [...action.payload] },
      };
    case SET_CITATION_VEHICLES:
      return {
        ...state,
        currentCitation: { ...state.currentCitation, vehicles: [...action.payload] },
      };
    case SET_CITATION_LOCATIONS:
      return {
        ...state,
        currentCitation: { ...state.currentCitation, locations: [...action.payload] },
      };
    case SET_CITATION_VIOLATIONS:
      return {
        ...state,
        currentCitation: { ...state.currentCitation, violations: [...action.payload] },
      };
    case SET_CITATION_TAGS:
      return {
        ...state,
        currentCitation: { ...state.currentCitation, tags: [...action.payload] },
      };
    case SET_CITATION_ATTACHMENTS:
      return {
        ...state,
        currentCitation: { ...state.currentCitation, attachments: [...action.payload] },
      };
    case SET_CITATION_NOTES:
      return {
        ...state,
        currentCitation: { ...state.currentCitation, notes: [...action.payload] },
      };
    case SET_ACTIVE_CITATIONS:
      return { ...state, activeCitations: action.payload };
    case SET_TOTAL_ACTIVE_CITATIONS:
      return { ...state, totalActiveCitations: action.payload };
    case SET_CITATION_FROM_DATE:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, citationFrom: action.payload },
      };
    case SET_CITATION_TO_DATE:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, citationTo: action.payload },
      };
    case SET_CITATION_TIME_PERIOD_VALUE:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, citationTimePeriod: action.payload },
      };
    case SET_CITATION_PARTY_NAME:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, partyName: action.payload },
      };
    case SET_CITATION_PARTY_TYPE:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, partyType: action.payload },
      };
    case SET_CITATION_ADDRESS:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, placeOrAddress: action.payload },
      };
    case SET_CITATION_QUICK_FILTER:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, quickFilter: action.payload },
      };
    case SET_CITATION_GROUP_BY:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, groupBy: action.payload },
      };
    case SET_CITATION_SORT_BY:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, sortBy: action.payload },
      };
    case SET_CITATION_SORT_BY_DIRECTION:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, sortByDirection: action.payload },
      };
    case SET_CITATION_CURRENT_VIEW:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, currentView: action.payload },
      };
    case SET_CITATION_EXPAND_OR_NOT:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, expandOrNot: action.payload },
      };
    case SET_CITATION_FILTER_FORM_VALUES:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, dialogFilters: { ...action.payload } },
      };
    case SET_SERVED_FROM_DATE:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, servedFrom: action.payload },
      };
    case SET_SERVED_TO_DATE:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, servedTo: action.payload },
      };
    case SET_SERVED_TIME_PERIOD_VALUE:
      return {
        ...state,
        citationFilters: { ...state.citationFilters, servedTimePeriod: action.payload },
      };
    case SET_CITATION_PRINTED_STATE:
      return {
        ...state,
        currentCitation: {
          ...state.currentCitation,
          citation: {
            ...state?.currentCitation?.citation,
            IsPrinted: true,
          },
        },
      };
    case CLEAR_ALL_CITATION_FILTERS:
      return {
        ...state,
        citationFilters: {
          ...state.citationFilters,
          citationFrom: action.payload.citationFrom,
          citationTo: action.payload.citationTo,
          servedFrom: action.payload.servedFrom,
          servedTo: action.payload.servedTo,
          citationTimePeriod: action.payload.citationTimePeriod,
          servedTimePeriod: action.payload.servedTimePeriod,
          partyName: action.payload.partyName,
          partyType: action.payload.partyType,
          placeOrAddress: action.payload.placeOrAddress,
          quickFilter: action.payload.quickFilter,
          dialogFilters: action.payload.dialogFilters,
        },
      };
  }

  return state;
}
